import styled from 'styled-components';
import config from '../../../components/config';

export default styled.section`
  background: ${config.palette.secondaryGraylight('0.4')};
  border-radius: 0.6rem;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
  padding: 2rem;

  strong {
    display: block;
    margin-bottom: 0.4rem;
  }

  &.hreflang-error {
    background: ${config.palette.errorRed};
    color: ${config.palette.white};
  }
`;
