import styled from 'styled-components';

import config from '../config';

const Tags = styled.section`
  display: flex;
  padding-bottom: 3.2rem;
  padding-top: 3.2rem;

  label:nth-child(n + 2) {
    margin-left: 0.8rem;
  }

  & > label {
    background-color: ${config.palette.secondaryGraylight('0.1')};
    border-radius: 0.2rem;
    color: ${config.palette.darkBlueTransparent('0.8')};
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.4rem 0.8rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-bottom: 2.4rem;
    padding-top: 1.6rem;

    & > label {
      font-size: 1.2rem;
    }
  }
`;

const Header = styled.section`
  & > h1 {
    color: ${config.palette.secondaryDarkBlue};
    font-weight: 700;
    line-height: 120%;

    @media (max-width: ${config.resolutions.mobileL}) {
      color: ${config.palette.darkBlue};
      font-family: Roboto, Georgia, serif;
      font-size: 2.4rem;
      line-height: 130%;
    }
  }

  & > img {
    display: block;
    border-radius: ${config.borderRadius};
    height: 39.64rem;
    object-fit: cover;
    width: 100%;

    @media (max-width: ${config.resolutions.mobileL}) {
      border-radius: 0;
      height: 24.3rem;
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: 100vw;
      position: relative;
      right: 50%;
      width: 100vw;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
`;

const Subtitle = styled.p`
  color: ${config.palette.darkBlueTransparent('0.72')};
  font-size: 2rem;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 4.8rem;
  text-justify: justify;

  @media (max-width: ${config.resolutions.mobileL}) {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }
`;

export { Tags, Subtitle, Header };
