import React from 'react';
import _ from 'lodash/fp';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Title1 } from '../atoms/headers';
import { Tags, Subtitle, Header } from './styles';
import useBlogCategories from '../../hooks/useBlogCategories';
import { ItemDatoBlogInfo as BlogArticle } from '../../services/interfaces';

type Props = Pick<
  BlogArticle,
  'title' | 'mainCategory' | 'secondaryCategory' | 'subtitle' | 'headerImage'
> & { showTags?: boolean };

const BlogHeader = (props: Props): React.ReactElement => {
  const {
    title,
    mainCategory,
    secondaryCategory,
    subtitle,
    headerImage,
    showTags = true,
  } = props;
  const categoriesMap = useBlogCategories();

  return (
    <Header as="section">
      <Title1 as="h1">{title}</Title1>
      {showTags && (
        <Tags>
          <label>{categoriesMap[mainCategory.code].description}</label>
          {secondaryCategory && (
            <label>{categoriesMap[secondaryCategory.code].description}</label>
          )}
        </Tags>
      )}
      {subtitle !== '' && <Subtitle as="p">{subtitle}</Subtitle>}
      {headerImage && (
        <GatsbyImage
          alt={headerImage.alt}
          image={headerImage.gatsbyImageData}
          title={headerImage.title}
        />
      )}
    </Header>
  );
};

export default BlogHeader;
