import React from 'react';
import { GraphQLClient, ClientContext, useQuery } from 'graphql-hooks';
import fetch from 'node-fetch';
import Helmet from 'react-helmet';

import PreviewMessage from './styles';
import GlobalStyles from '../../../components/GlobalStyles';
import Layout from '../../../components/atoms/layout';
import Header from '../../../components/BlogHeader';
import Body from '../../../components/BlogBody';
import { LayoutBlogArticles } from '../../../template/BlogArticle/styles';
import urls from '../../../../url.json';
import { datoCategoryToWebUrl } from '../../../../build-utils/dato-utils';
import { BlogArticle } from '../../../interface/template';

function generateBlogArticleUrl(
  lang: string,
  mainCategory: { code: string },
  slugTitle: string
): string {
  const category = urls[datoCategoryToWebUrl(mainCategory.code)][lang];
  return `/${lang}/${category}/${slugTitle}`;
}

interface PreviewProps {
  articleId: string;
}
const Preview = (props: PreviewProps): JSX.Element => {
  const { articleId } = props;
  const { loading, error, data } = useQuery(
    `query allBlogArticles {
        allBlogArticles {
          id
          content
          headerImage {
            url
            title
          }
          hreflang {
            topic
          }
          language {
            code
          }
          slugTitle
          title
          subtitle
          mainCategory {
            ... on CityRecord {
              code
            }
            ... on CourseRecord {
              code
            }
            ... on BlogCategoryRecord {
              code
            }
          }
          secondaryCategory {
            ... on CityRecord {
              code
            }
            ... on CourseRecord {
              code
            }
            ... on BlogCategoryRecord {
              code
            }
          }
          seoInfo {
            image {
              id
              url
            }
            description
            title
            twitterCard
          }
        }
      }`
  );
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!data.allBlogArticles || data.allBlogArticles.length === 0) {
    return <div>The article with id {articleId} doesn&apos;t exist</div>;
  }

  const article = data.allBlogArticles.find(
    (blogArticle: BlogArticle): boolean => blogArticle.id === articleId
  );

  if (article === undefined) {
    return (
      <div>
        The blog article with id {articleId} can&apos;t be fetched from dato.
      </div>
    );
  }

  const showHreflangWarning =
    data.allBlogArticles.filter(
      (blogArticle: BlogArticle): boolean =>
        blogArticle.language.code === article.language.code &&
        blogArticle.hreflang.topic === article.hreflang.topic &&
        blogArticle.id !== articleId
    ).length > 0;

  return (
    <>
      <GlobalStyles />
      <Helmet
        defer={false}
        title="This is a preview version of the blog article"
      >
        <meta content="noindex, nofollow" name="robots" />
        <meta content="noindex, nofollow" name="googlebot" />
      </Helmet>
      <Layout extend={LayoutBlogArticles}>
        <PreviewMessage>
          <strong>
            NOTE: This section will not appear in the article once it&apos;s
            published
          </strong>
          URL:{' '}
          {generateBlogArticleUrl(
            article.language.code.toLowerCase(),
            article.mainCategory,
            article.slugTitle
          )}
        </PreviewMessage>
        {showHreflangWarning && (
          <PreviewMessage className="hreflang-error">
            The hreflang &quot;{article.hreflang.topic}&quot; in the current
            language is already in usage. Please, select or create another
            hreflang topic before publish this article.
          </PreviewMessage>
        )}
        <Header {...article} showTags={false} />
        <Body {...article} />
      </Layout>
    </>
  );
};

const BlogArticlePreview = (props: any): JSX.Element => {
  const id = props.location.search.replace('?id=', '');
  /*
   * Fetch method is done this way to be able to use it in production and
   * avoid build failures due to its usage.
   */
  const client = new GraphQLClient({
    url: process.env.GATSBY_DATOCMS_PREVIEW_URL,
    fetch: (...props): Promise<object> => fetch(...props),
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_DATOCMS_READONLY_TOKEN}`,
    },
  });

  return (
    <React.StrictMode>
      <ClientContext.Provider value={client}>
        <Preview articleId={id} />
      </ClientContext.Provider>
    </React.StrictMode>
  );
};

export default BlogArticlePreview;
